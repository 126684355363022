<template>
  <section>
    <!-- v-if="getThisOrder.orderStatus !== 6" -->
    <div
      class="order__modal"
      :class="loadingButton ? `click__none` : ``"
      ref="openOrderModal"
    >
      <div
        :class="[
          closeModal ? `slide__right` : `slide__left`,
          !viewGPS && !viewGPSLink ? `order__modal--block` : `order__modal--gps`
        ]"
      >
        <div @click="closeModalOrder" class="order__modal--close">
          <div>
            <img src="../../assets/images/close.svg" alt="Закрыть" />
          </div>
        </div>
        <section
          v-if="Object.keys(getThisOrder).length !== 0"
          class="modal--data"
          ref="modal"
        >
          <div
            v-if="leftViewTab"
            @mouseover="leftViewTabHandler"
            @mouseleave="clearIntervalHandler"
            class="modal--data--arrblock arrblock__left"
          >
            <img src="../../assets/images/arrow_left.png" alt="Влево" />
          </div>
          <div
            v-if="rightViewTab"
            @mouseover="rightViewTabHandler"
            @mouseleave="clearIntervalHandler"
            class="modal--data--arrblock arrblock__right"
          >
            <img src="../../assets/images/arrow_right.png" alt="Вправо" />
          </div>
          <div class="modal__data--header">
            <div
              class="modal__data--status"
              :class="getThisOrder.orderStatusData.color"
            >
              {{ getThisOrder.orderStatusData.name }}
            </div>
            <div class="modal__data--number">№ {{ getThisOrder.orderId }}</div>
          </div>
          <div class="modal__data--empty"></div>
          <div class="modal__data--block">
            <div class="modal__data--main">
              <div class="modal__body">
                <div class="modal__body--element">
                  <div style="width: 384px">
                    <div class="modal__body--form">
                      <div class="modal__form">
                        <form-input
                          label="Дата подачи"
                          width="87"
                          class="modal__input"
                          :value="getThisOrder.dateToView"
                          readonly
                        ></form-input>
                        <img
                          class="input__custom--icon"
                          src="../../assets/images/modal-order/calendar.svg"
                          alt="Дата подачи"
                        />
                      </div>
                      <div class="modal__form">
                        <form-input
                          label="Время подачи"
                          width="95"
                          class="modal__input"
                          :value="getThisOrder.timeToView"
                          readonly
                        ></form-input>
                        <img
                          class="input__custom--icon"
                          src="../../assets/images/modal-order/time.svg"
                          alt="Время подачи"
                        />
                      </div>
                    </div>
                    <div class="modal__body--form">
                      <form-select
                        v-if="getThisOrder.orderType !== 2"
                        label="Тоннаж"
                        :options="tonnageArray"
                        :value="getThisOrder.tonnage"
                        readonly
                      ></form-select>
                      <form-select
                        v-if="getThisOrder.orderType !== 2"
                        label="Тип ТС"
                        :options="boxTypeArray"
                        :value="getThisOrder.boxType"
                        readonly
                      ></form-select>
                      <form-select
                        v-if="getThisOrder.orderType === 2"
                        label="Вид грузчика"
                        :options="orderTypeViewArray"
                        :value="getThisOrder.orderTypeCargo"
                        readonly
                      ></form-select>
                    </div>
                    <div  class="modal__body--form" v-if="userData.useRequestNumber">
                      <form-input
                        readonly
                        :value="getThisOrder.requestNumber"
                        label="Номер заявки"
                        style="width: 175px;"
                        :styles="'height: 31px'"
                      ></form-input>
                    </div>
                    
                  </div>
                  <div v-if="getThisOrder.orderType !== 2">
                    <div
                      style="
                        display: grid;
                        grid-template-columns: 170px 170px 170px 170px;
                        grid-template-rows: 30px 30px 30px;
                      "
                    >
                      <div
                        v-for="(option, i) in getOptionDopList"
                        :key="i"
                        style="grid-column: span 1; grid-row: span 1"
                      >
                        <form-checkbox
                          :value="option[0]"
                          :label="option[1]"
                          :checked="getThisOrder.orderOption[option[0]]"
                          :disabled="!getThisOrder.orderOption[option[0]]"
                        ></form-checkbox>
                      </div>
                    </div>
                  </div>
                  <div v-if="getThisOrder.orderNote">
                    <form-textarea
                      label="Коментарий к заказу"
                      width="140"
                      :value="getThisOrder.orderNote"
                    ></form-textarea>
                  </div>
                </div>
                <div
                  v-if="getThisOrder.routeArray.length > 0"
                  class="modal__body--header"
                >
                  <h4 v-if="getThisOrder.orderType === 1">
                    Маршрут
                    <span v-if="getThisOrder.routeArray.length > 6">
                      &mdash; {{ getThisOrder.routeArray.length }} точек
                    </span>
                  </h4>
                  <h4 v-else>
                    Места работы
                    <span v-if="getThisOrder.routeArray.length > 6">
                      &mdash; {{ getThisOrder.routeArray.length }} точек
                    </span>
                  </h4>
                  <div style="position: relative">
                    <img
                      v-if="getThisOrder.routeArray.length > 1"
                      @click="
                        copyRouteArray('routeArray', getThisOrder.routeArray)
                      "
                      class="executor__copy"
                      style="cursor: pointer; margin-left: 10px"
                      src="@/assets/images/modal-order/copy.svg"
                      alt="Копировать"
                      title="Копировать список маршрутов"
                    />
                    <div
                      v-if="copyRouteArrayData.includes('routeArray')"
                      class="copydataModal"
                      style="top: -4px"
                    >
                      Скопировано в буфер
                    </div>
                  </div>
                </div>
                <!-- Вывод списка маршрутов -->
                <div
                  v-if="getThisOrder.routeArray.length > 0"
                  class="modal__table"
                  style="
                    margin-left: -16px;
                    max-height: 310px;
                    overflow-y: auto;
                    position: relative;
                  "
                >
                  <div
                    class="modal__table--route"
                    style="padding-bottom: 16px; position: relative"
                  >
                    <div
                      class="modal__table--item"
                      v-for="(route, idx) in getThisOrder.routeArray"
                      :key="idx"
                    >
                      <div class="route__move">
                        <img src="../../assets/images/modal-order/move.svg" />
                      </div>
                      <div class="route__index">
                        {{ idx + 1 }}
                      </div>
                      <div class="route__type">
                        <div class="route__type--block">
                          <img
                            v-if="route.type === `Загрузка`"
                            class="route__type--img"
                            src="../../assets/images/modal-order/truck_up.svg"
                            alt="Truck Up"
                          />
                          <img
                            v-if="route.type === `Выгрузка`"
                            class="route__type--img"
                            src="../../assets/images/modal-order/truck_down.svg"
                            alt="Truck Down"
                          />
                          {{ route.type }}
                        </div>
                      </div>
                      <div class="route__address--new">
                        <img src="../../assets/images/route.svg" alt="Адрес" />
                        <form-input
                          type="text"
                          :value="route.address"
                          :autoadd="false"
                        ></form-input>
                      </div>
                      <div class="route__comment">
                        <form-input
                          type="text"
                          label="Комментарий (Ф.И.О., телефон, примечание)"
                          :value="route.comment"
                        ></form-input>
                      </div>
                      <div class="route__arrival">
                        <form-input
                          type="text"
                          label="Прибыл/убыл фактически"
                          :value="`${route.arrivalTime}--${route.arrivalDate} | ${route.departedTime}--${route.departedDate}`"
                          readonly
                        ></form-input>
                      </div>
                    </div>
                    <!-- <div
                      v-if="getThisOrder.routeArray.length > 6"
                      class="block__shadow"
                    ></div> -->
                  </div>
                </div>
                <section
                  v-if="
                    getTemplateTarifTableCell.length &&
                    Object.keys(this.tarifCells).length !== 0 &&
                    getThisOrder.orderStatus !== 1
                  "
                  class="profile__tarif"
                >
                  <div class="modal__body--header">
                    <h4>Рассчет стоимости</h4>
                    <div class="profile__tarif--action">
                      <span @click="setActivePage">Мой тариф</span>
                    </div>
                  </div>
                  <table>
                    <thead>
                      <tr>
                        <th
                          v-for="(cell, idx) in getTableHeadFirst"
                          :key="idx"
                          :rowspan="cell.rowspan"
                          :colspan="cell.colspan"
                          :class="idx === 0 ? `personal__border` : ``"
                          :style="
                            cell.colspan === 2
                              ? `border-bottom: none`
                              : `height: 57px; width: ${cell.width}px`
                          "
                        >
                          {{ cell.all ? cell.all : `` }}
                          {{ !cell.all ? cell.name : `` }}
                        </th>
                      </tr>
                      <tr>
                        <th
                          v-for="cell in getTableHeadSecond"
                          :key="cell.id"
                          :style="`height: 20px; width: ${cell.width}px`"
                        >
                          {{ cell.name }}
                        </th>
                      </tr>
                    </thead>
                    <tbody v-if="getTemplateTarifTableCell">
                      <tr>
                        <td
                          v-for="cell in getTemplateTarifTableCell"
                          :key="cell.id"
                          :style="`height: 34px; width: ${cell.width}px`"
                        >
                          {{ tarifCells[cell.id] | roundHours }}
                        </td>
                      </tr>
                    </tbody>
                    <!-- <tfoot>
                      <tr>
                        <td :colspan="getTemplateTarifTableCell.length">
                          <div class="profile__tarif--action">
                            <router-link to="/profile"
                              ><span @click="setActivePage"
                                >Мой тариф</span
                              ></router-link
                            >
                          </div>
                        </td>
                      </tr>
                    </tfoot> -->
                  </table>
                </section>
                <div v-if="getThisOrder.executor" class="modal__body--header">
                  <h4>Исполнитель</h4>
                </div>
                <div class="executor__block">
                  <div
                    v-if="getThisOrder.driver && getThisOrder.driver.length > 0"
                    class="modal__executor"
                  >
                    <div class="modal__executor--left">
                      <div class="modal__executor--title">
                        <div class="executor__logo">
                          <img
                            @click="openLightbox"
                            :src="getThisOrder.executorPhoto"
                            alt="Фото"
                          />
                          <FsLightbox
                            type="image"
                            :toggler="toggler"
                            :sources="[getThisOrder.executorPhoto]"
                          />
                        </div>
                        <div class="executor__name">
                          <div
                            v-for="item in getThisOrder.driverFIO"
                            :key="item"
                          >
                            {{ item }}
                          </div>
                        </div>
                        <img
                          @click="
                            copyExecutorData(
                              getThisOrder.orderId,
                              getThisOrder.copy
                            )
                          "
                          class="executor__copy"
                          src="../../assets/images/modal-order/copy.svg"
                          alt="Копировать"
                          title="Копировать данные водителя в буфер"
                        />
                        <div
                          v-if="copydata.includes(getThisOrder.orderId)"
                          class="copydataModal"
                        >
                          Скопировано в буфер
                        </div>
                        <img
                          v-if="getThisOrder.driverDocPhoto.length"
                          @click="openLightboxDocs(getThisOrder.driverDocPhoto)"
                          class="executor__docs"
                          src="../../assets/images/modal-order/doc.svg"
                          alt="Документы"
                          title="Документы водителя"
                        />
                      </div>
                      <div class="modal__executor--data">
                        <div class="executor__phone">
                          <div
                            @click="
                              getThisOrder.driverPhoneLink
                                ? callToDriver(getThisOrder.driverPhoneLink)
                                : ''
                            "
                          >
                            <!-- <img
                            src="../../assets/images/modal-order/phone.svg"
                            alt="Телефон"
                            title="Позвонить водителю"
                          /> -->
                            <div>{{ getThisOrder.driverPhone }}</div>
                          </div>
                        </div>
                        <button
                          v-if="
                            (getThisOrder.orderStatus === 2 ||
                              getThisOrder.orderStatus === 3 ||
                              getThisOrder.orderStatus === 11) &&
                            ((getThisOrder.driverData.driverDevice &&
                              getThisOrder.orderRouteDriverAppCount > 0) ||
                              getThisOrder.gps)
                          "
                          @click="openGPSHandler(getThisOrder)"
                          class="executor__gps"
                        >
                          <img
                            src="../../assets/images/modal-order/gps.svg"
                            alt="GPS"
                          />
                          GPS
                        </button>
                      </div>
                    </div>
                    <div class="modal__executor--right">
                      <div
                        v-if="getThisOrder.driverData"
                        class="modal__executor--car"
                      >
                        <div class="driver__car">
                          <h4>
                            {{ getThisOrder.driverData.driverCarModel }}
                            {{ getThisOrder.driverData.driverCarNumber }}
                          </h4>
                        </div>
                        <!-- v-if="getThisOrder.carPhoto.length === 5" -->
                        <photo-slider
                          :orderId="getThisOrder.orderId"
                          v-if="getThisOrder.carPhoto.length"
                          :imageArray="getThisOrder.carPhoto"
                        ></photo-slider>
                        <div v-else class="photo__car">
                          <div class="photo__car--item">
                            <img
                              src="../../assets/images/no_photo.svg"
                              alt="Фото скоро будет"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    v-if="getThisOrder.loader && getThisOrder.loader.length > 0"
                    class="modal__loader"
                  >
                    <div
                      class="modal__executor--title"
                      style="margin-bottom: 0"
                    >
                      <div class="executor__logo">
                        <img
                          @click="openLightbox"
                          :src="getThisOrder.executorPhoto"
                          alt="Фото"
                        />
                        <FsLightbox
                          type="image"
                          :toggler="toggler"
                          :sources="[getThisOrder.executorPhoto]"
                        />
                      </div>
                      <div class="executor__name" style="padding-top: 5px">
                        <div v-for="item in getThisOrder.loaderFIO" :key="item">
                          {{ item }}
                        </div>
                        <div class="executor__phone">
                          <div
                            @click="
                              getThisOrder.loaderPhoneLink
                                ? callToDriver(getThisOrder.loaderPhoneLink)
                                : ''
                            "
                          >
                            <div>{{ getThisOrder.loaderPhone }}</div>
                          </div>
                        </div>
                      </div>
                      <div>
                        <div>
                          <img
                            @click="
                              copyExecutorData(
                                getThisOrder.orderId,
                                getThisOrder.copy
                              )
                            "
                            class="executor__copy"
                            style="top: 5px; right: -65px"
                            src="../../assets/images/modal-order/copy.svg"
                            alt="Копировать"
                            title="Копировать данные водителя в буфер"
                          />
                          <div
                            v-if="copydata.includes(getThisOrder.orderId)"
                            class="copydataModal"
                            style="right: -240px; top: 0"
                          >
                            Скопировано в буфер
                          </div>
                        </div>
                        <div v-if="getThisOrder.loaderDocPhoto.length">
                          <img
                            @click="
                              openLightboxDocs(getThisOrder.loaderDocPhoto)
                            "
                            class="executor__docs"
                            style="top: 45px; right: -65px"
                            src="../../assets/images/modal-order/doc.svg"
                            alt="Документы"
                            title="Документы грузчика"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    v-if="!getThisOrder.copy && getThisOrder.orderStatus !== 5"
                    class="modal__executor"
                  >
                    <div class="executor__none">
                      <h2>
                        {{ getName }} подбирает для вас исполнителя, скоро его
                        данные появятся здесь
                      </h2>
                      <!-- <Lottie
                        :options="defaultOptions"
                        :width="200"
                        :height="200"
                        class="executor__none--loader"
                      /> -->
                      <Lottie
                        :options="defaultOptions"
                        :width="80"
                        :height="80"
                        class="executor__none--loader"
                      />
                    </div>
                  </div>
                  <div
                    v-if="getThisOrder.docFileLink"
                    class="executor__block"
                    style="margin: 19px 0 0 47px"
                  >
                    <div style="margin-right: 13px">
                      <img
                        src="../../assets/images/docs-yes.svg"
                        alt="Документы по рейсу"
                      />
                    </div>
                    <div :style="!loadDoc ? `` : `display: flex;`">
                      <h4
                        style="
                          font-size: 14px;
                          font-weight: 500;
                          margin-bottom: 9px;
                        "
                      >
                        Документы по рейсу
                      </h4>
                      <a
                        @click.prevent="openInNewTab(getThisOrder.orderId)"
                        href="#"
                        v-if="!loadDoc"
                        title="Посмотреть документы к рейсу"
                        style="text-decoration: underline"
                        >Посмотреть</a
                      >
                      <span style="position: relative" v-else>
                        <Lottie
                          :options="defaultOptions"
                          :width="50"
                          :height="50"
                          class="executor__none--loader executor__none--mini"
                        />
                      </span>
                    </div>
                  </div>
                  <photo-docs
                    v-if="photoDriverDocs.length"
                    :imageArray="formatedPhotoDriverDocs"
                    :orderId="getThisOrder.orderId"
                  ></photo-docs>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section v-if="!viewGPS && !viewGPSLink" class="modal--footer">
          <form-button
            v-if="getThisOrder.orderStatus === 5"
            class="modal--footer--btn button__yellow"
            @click="reopenOrder(getThisOrder.orderId)"
            title="ВОЗОБНОВИТЬ ЗАКАЗ"
            style="padding: 0 40px; width: auto"
          >
          </form-button>
          <div>
            <!-- <div v-if="loadingButton" class="loading__button">
              <img
                src="../../assets/images/loading__button.svg"
                alt="Загрузка"
              />
            </div> -->
            <!-- <Lottie
              v-if="loadingButton"
              :options="defaultOptions"
              :width="30"
              :height="30"
              class="loading__button"
            /> -->
            <button
              class="modal--footer--btn button__grey"
              @click="copyOrder(getThisOrder)"
              style="
                padding: 0 15px;
                display: flex;
                align-items: center;
                justify-content: space-between;
              "
            >
              ПОВТОРИТЬ ЗАКАЗ
              <img
                src="../../assets/images/loading__button.svg"
                alt="Загрузка"
                style="margin-left: 16px"
              />
            </button>
          </div>
          <div v-if="this.getTime < getThisOrder.formatedDateTime">
            <!-- :disabled="this.getTime > currentOrder.formatedDateTime" -->
            <form-button
              v-if="
                getThisOrder.orderStatus === 1 || getThisOrder.orderStatus === 2
              "
              class="modal--footer--btn button__grey"
              @click="cancelOrder"
              title="ОТМЕНИТЬ ЗАКАЗ"
            >
            </form-button>
          </div>
          <div>
            <button
              class="modal--footer--btn button__grey"
              @click="openWhatsapp"
              style="
                padding: 0 15px;
                display: flex;
                align-items: center;
                justify-content: space-between;
              "
            >
              ЧАТ С МЕНЕДЖЕРОМ
              <i class="fab fa-whatsapp" style="margin-left: 16px"></i>
            </button>
          </div>
        </section>
        <iframe
          v-if="viewGPSLink"
          :src="viewGPSLink"
          width="100%"
          height="100%"
        ></iframe>
        <the-leaflet
          v-if="viewGPS"
          :driverId="viewGPSDriver"
          :orderId="viewGPSOrder"
          :driverData="viewGPSDriverData"
          :typeDriver="viewGPSDriver ? true : false"
          :typeOrder="viewGPSOrder ? true : false"
        ></the-leaflet>
      </div>
    </div>
    <!-- <div
      v-if="getThisOrder.orderStatus === 6 && openSecondModal"
      class="order__modal"
    >
      <div
        class="modal__second"
        :class="openSecondModal ? `slide__bottom` : `slide__top`"
        ref="newSecondModal"
      >
        <div class="modal__second--big">
          <div class="modal__second--close">
            <img
              @click="closeSecondModal"
              src="../../assets/images/close.svg"
              alt="Закрыть"
            />
          </div>
          <section>
            <h1>ХЕРНЯ</h1>
          </section>
        </div>
      </div>
    </div> -->
    <div v-if="openGPS" class="order__modal" ref="newGPSModal">
      <div
        class="order__modal--gps"
        :class="openGPS ? `slide__left` : `slide__right`"
      >
        <div @click="closeGPS" class="order__modal--close">
          <div>
            <img src="../../assets/images/close.svg" alt="Закрыть" />
          </div>
        </div>
        <the-leaflet
          v-if="openGPSTypeOrder || openGPSTypeDriver"
          :orderId="openGPSOrderId"
          :driverId="openGPSDriverId"
          :driverData="openGPSDriverData"
          :typeOrder="openGPSTypeOrder"
          :typeDriver="openGPSTypeDriver"
        ></the-leaflet>
        <iframe
          v-else-if="openGPSLink"
          :src="openGPSLink"
          width="100%"
          height="100%"
        ></iframe>
      </div>
    </div>
  </section>
</template>

<script>
// Form
import FormTextarea from "../form/form-textarea";
import FormInput from "../form/form-input";
import FormSelect from "../form/form-select";
import FormCheckbox from "../form/form-checkbox";
import FormButton from "../form/form-button";
// Slider
import PhotoSlider from "../main/photo-slider";
import PhotoDocs from "../main/photo-docs";
// LightBox
import FsLightbox from "fslightbox-vue";
// Данные по селектам
import { carTonnageData } from "../../data/car-tonnage";
import { carBoxType } from "../../data/car-box-type";
// Данные по чекбоксам
import { orderOptionsData } from "../../data/order-options";
// Данные по тарифам
import { ClientOrderTarifArray } from "../../data/client-order-tarif";
import { OrderLoaderTarifArray } from "../../data/client-order-loader-tarif";
// Data
import { orderStatus } from "../../data/order-status";
import { orderPayment } from "../../data/order-payment";
// Animation
import Lottie from "vue-lottie";
import animationData from "../../assets/animations/circle-loader.js";
// Leaflet
import TheLeaflet from "@/components/main/the-leaflet";
// Store
import { mapActions, mapState } from "vuex";

import openBase64 from "@/mixins/open-base64.js";

export default {
  mixins: [openBase64],
  name: "OrderModal",
  props: {
    currentOrder: Object,
    orderId: Number,
    viewGPS: Boolean,
    viewGPSDriver: Number,
    viewGPSDriverData: Object,
    viewGPSOrder: Number,
    viewGPSLink: [Number, String]
  },
  components: {
    FormInput,
    FormSelect,
    FormCheckbox,
    FormTextarea,
    FormButton,
    PhotoSlider,
    PhotoDocs,
    FsLightbox,
    Lottie,
    TheLeaflet
  },
  data() {
    return {
      loadDoc: false,
      thisOrder: {},
      closeModal: false,
      copydata: [],
      copyRouteArrayData: [],
      openGPS: false,
      openGPSLink: null,
      openGPSOrderId: null,
      openGPSDriverId: null,
      openGPSDriverData: {},
      openGPSTypeOrder: false,
      openGPSTypeDriver: false,
      statusList: orderStatus,
      paymentList: orderPayment,
      zaprosUrl: "",
      photoDriverDocs: [],
      orderTypeViewArray: [
        { name: "Грузчик", value: "Грузчик" },
        { name: "Такелажник", value: "Такелажник" }
      ],
      tonnageArray: carTonnageData,
      boxTypeArray: carBoxType,
      optionDopList: orderOptionsData,
      toggler: false,
      leftViewTab: false,
      rightViewTab: false,
      pageWidth: 0,
      scrollWidth: 0,
      resultWidth: 0,
      leftScroll: 0,
      rightScroll: 0,
      defaultOptions: { animationData: animationData },
      animationSpeed: 1,
      tarifCells: {},
      loadingButton: false,
      getTimeNowInterval: 0,
      getTime: 0,
      checkOrderInterval: 0,
      checkTimeInterval: 10000
      // openSecondModal: false
    };
  },
  computed: {
    ...mapState(["serverPath", "userData", "token"]),

    getOptionDopList() {
      return Object.entries(this.optionDopList);
    },
    getName() {
      return this.userData.manager.trim().split(" ")[1];
    },
    // Данные по таблице
    getOrderTarifTable() {
      if (this.getThisOrder.orderType === 1) {
        return ClientOrderTarifArray.filter(
          (item) => item.value === this.userData.paymentType
        )[0];
      } else {
        return OrderLoaderTarifArray[0];
      }
    },
    // getOrderTarifTableCell() {
    //   return this.getOrderTarifTable.cell;
    // },
    // getTemplateTarifTableCell() {
    //   return this.getOrderTarifTable.cell.filter((item) => {
    //     if (this.tarifCells[item.id] && this.tarifCells[item.id] != 0) {
    //       return item;
    //     }
    //   });
    // },
    getTemplateTarifTableCell() {
      return this.getOrderTarifTable.cell;
    },
    getTableHeadFirst() {
      return this.getTemplateTarifTableCell.filter((item) => item.all !== 1);
    },
    getTableHeadSecond() {
      return this.getTemplateTarifTableCell.filter((item) => item.all !== "");
    },
    getThisOrder() {
      let item = "";
      if (Object.keys(this.thisOrder).length !== 0) {
        item = this.thisOrder;
        const i = +item.orderStatus - 1;
        item.orderStatusData = {
          color: `order__status--${this.statusList[i].color}`,
          name: this.statusList[i].name
        };
        // Оплата заказа
        let y = 1;
        if (item.paymentClientStatus) {
          y = 0;
        } else if (item.orderInvoice) {
          y = 2;
        }
        item.statusPayment = {
          color: `main__status--${this.paymentList[y].color}`,
          name: this.paymentList[y].name
        };
        // Форматирование даты для фильтрации
        item.formatedDate = new Date(item.createDate).getTime();
        item.formatedTime = new Date(item.createTime).getTime();
        item.formatedDateTime = item.formatedDate + item.formatedTime;
        // Дата и время для отображения
        item.dateToView = new Date(item.createDate).toLocaleDateString();
        item.timeToView = new Date(item.createTime).toLocaleTimeString([], {
          hour: "2-digit",
          minute: "2-digit"
        });
        // Дата для отображения в форме
        item.dateToForm = this.getTrueDataToInput(item.createDate, "-");
        // Тоннаж
        item.tonn = "";
        item.tonnFilter = null;
        if (item.tonnage) {
          let tonnageText = "тонн";
          if (item.tonnage == 1) {
            tonnageText = "тонны";
          }
          const tonnageView = `до ${item.tonnage} ${tonnageText}`;
          item.tonn = tonnageView;
          item.tonnFilter = tonnageView;
        } else if (!item.tonnage && item.orderType === 2) {
          item.tonn = "Грузчик";
        }
        // Тип кузова
        item.boxTypeName = "";
        carBoxType.map((elem) => {
          if (elem.value === item.boxType) {
            item.boxTypeName = elem.view;
          }
        });
        // Тип заказа
        if (item.orderType === 1) {
          item.orderTypeView = "Транспорт";
        } else {
          item.orderTypeView = "Грузчик";
        }
        // Вывод массива точек маршрута при наведении
        item.routeTitle = "";
        if (item.allPointsRoute) {
          item.routeTitle = item.allPointsRoute.map((item) => {
            return item.address;
          });
          item.routeTitle = item.routeTitle.join("\n");
        }
        // Массив дополнительных опций
        item.dopOptions = [];
        for (let key in item.orderOption) {
          if (item.orderOption[key]) {
            item.dopOptions.push(key);
          }
        }
        // Ссылка на документ
        item.docFileLink = null;
        if (item.docClientFile) {
          item.docFileLink = `${this.serverPath}order/${item.orderId}/doc/${item.docClientFile}`;
        }
        // Переписываем данные по маршруту
        const routeArray = [];
        if (item.allPointsRoute.length > 0) {
          item.allPointsRoute.map((item) => {
            const route = {
              type: item.loaderType ? item.loaderType : "Выгрузка",
              arrivalTime: item.arrivalTime
                ? new Date(item.arrivalTime).toLocaleTimeString([], {
                    hour: "2-digit",
                    minute: "2-digit"
                  })
                : "00:00",
              arrivalDate: item.arrival
                ? new Date(item.arrival).toLocaleDateString()
                : null,
              address: item.address ? item.address : null,
              departedTime: item.departedTime
                ? new Date(item.departedTime).toLocaleTimeString([], {
                    hour: "2-digit",
                    minute: "2-digit"
                  })
                : "00:00",
              departedDate: item.departed
                ? new Date(item.departed).toLocaleDateString()
                : null,
              comment: item.shipper
            };
            routeArray.push(route);
          });
        }
        item.routeArray = [...routeArray];
        // Данные по исполнителю
        item.executor = "Идет подбор исполнителя";
        item.gps = null;
        item.copy = null;
        item.carPhoto = [];
        item.driverDocPhoto = [];
        item.loaderDocPhoto = [];
        item.executorPhoto = "";
        item.driverPhone = "";
        item.driverPhoneLink = "";
        if (item.driver && item.driver.length > 0) {
          const driver = item.driver[0];
          item.driverData = driver;
          // ФИО водителя
          item.executor = driver.driverFullName;
          // Ссылка GPS
          item.gps = driver.driverDevice ? "" : driver.driverGps;
          // Данные для копирования
          let driverCarData = "";
          if (driver.driverCarModel && driver.driverCarNumber) {
            driverCarData = `${driver.driverCarModel} ${driver.driverCarNumber}`;
          }
          let driverPhoneCopy = "";
          if (driver.driverPhone && item.orderStatus < 3) {
            driverPhoneCopy = `\nТел.: ${driver.driverPhone}`;
          }
          item.copy = `${driver.driverFullName}\nПаспорт ${driver.driverPassportSeries} ${driver.driverPassportNumber} выдан ${driver.driverPassportIssue}, ${driver.driverPassportDateIssue}\n${driverCarData}${driverPhoneCopy}`;
          // ДАННЫЕ ДЛЯ МОДАЛЬНОГО ОКНА
          // Фото водителя
          item.executorPhoto = driver.driverPhoto;
          // Фото автомобиля
          if (driver.driverCarPhoto) {
            item.carPhoto = driver.driverCarPhoto;
          }
          // // Документы водителя ### item.orderStatus < 3
          // if (
          //   (driver.driverDocs && item.orderStatus === 2) ||
          //   (driver.driverDocs && item.orderStatus === 11)
          // ) {
          //   for (let key in driver.driverDocs) {
          //     if (driver.driverDocs[key].docFileName) {
          //       if (key > 0 && key < 3) {
          //         item.driverDocPhoto.push(
          //           `${this.serverPath}drivers/${driver.driverId}/doc/${driver.driverDocs[key].docFileName}`
          //         );
          //       }
          //     }
          //   }
          // }
          // Ссылка на телефон ### item.orderStatus < 3
          if (item.orderStatus === 2 || item.orderStatus === 11) {
            item.driverPhoneLink =
              "+" + driver.driverPhone.match(/[\d]/g).join("");
            item.driverPhone = driver.driverPhone;
          } else {
            item.driverPhone = driver.driverPhone.slice(0, -5) + "XX-XX";
          }
          // Массив ФИО водителя
          item.driverFIO = [
            driver.driverLastName,
            driver.driverFirstName,
            driver.driverMiddleName
          ];
        } else if (item.loader && item.loader.length > 0) {
          const loader = item.loader[0];
          item.loaderData = loader;
          item.loaderPhone = "";
          item.loaderPhoneLink = "";
          let pathDocs = "loader",
            pathId = loader.loaderId;
          if (loader.loaderDriver) {
            pathDocs = "drivers";
            pathId = loader.loaderDriver;
          }
          // Ссылка на телефон
          if (item.orderStatus < 3) {
            item.loaderPhoneLink =
              "+" + loader.loaderPhone.match(/[\d]/g).join("");
            item.loaderPhone = loader.loaderPhone;
          } else {
            item.loaderPhone = loader.loaderPhone.slice(0, -5) + "XX-XX";
          }
          // ФИО грузчика
          item.executor = loader.loaderFullName;
          // Фото грузчика
          // item.executorPhoto = loader.loaderPhoto;
          item.executorPhoto = loader.loaderPhoto;
          // Массив ФИО грузчика
          item.loaderFIO = [loader.loaderLastName, loader.loaderFirstName];
          let loaderPhoneCopy = "";
          if (loader.loaderPhone && item.orderStatus < 3) {
            loaderPhoneCopy = `\nТел.: ${loader.loaderPhone}`;
          }
          item.copy = `${loader.loaderFullName}\nПаспорт ${loader.loaderPassportSeries} ${loader.loaderPassportNumber} выдан ${loader.loaderPassportIssue}, ${loader.loaderPassportDateIssue}${loaderPhoneCopy}`;
          // Документы грузчика
          if (loader.loaderDocs && item.orderStatus < 3) {
            for (let key in loader.loaderDocs) {
              if (loader.loaderDocs[key].docFileName) {
                if (key > 0 && key < 3) {
                  item.loaderDocPhoto.push(
                    `${this.serverPath + pathDocs}/${pathId}/doc/${
                      loader.loaderDocs[key].docFileName
                    }`
                  );
                }
              }
            }
          }
        }
        if (
          item.executor === "Идет подбор исполнителя" &&
          item.orderStatus > 4
        ) {
          item.executor = "";
        }
      }

      return item;
    },
    // Переделка фото по рейсу для отображения в массиве
    formatedPhotoDriverDocs() {
      const temp = [];
      this.photoDriverDocs.forEach((item) =>
        temp.push(`${this.serverPath}order/${this.orderId}/doc/${item.docFile}`)
      );
      return temp;
    }
  },
  filters: {
    roundHours(value) {
      if (value && value.includes(".")) {
        value = +value;
        return value.toFixed(2);
      } else {
        if (value === "" || value === "0") {
          return "";
        } else {
          return value;
        }
      }
    }
  },
  methods: {
    ...mapActions([
      "CHANGE_ACTIVE_PAGE",
      "CHANGE_PAGE_TAB",
      "CHANGE_ACTIVE_TARIF",
      "REFRESH_TOKEN_HANDLER",
      "GET_ORDERS",
      "UPDATE_ORDER",
      "ADD_ORDER_FROM_ROUTE"
    ]),
    async openInNewTab(id) {
      this.loadDoc = true;
      await this.axios
        .get(`/api/client/order/doc/get/${id}`, {
          headers: { Authorization: `Bearer ${this.token}` }
        })
        .then((response) => {
          this.printPreview(response.data.response);
          this.loadDoc = false;
        })
        .catch((e) => {
          if (e.response) {
            const { code, message } = e.response.data;
            if (code === 401 && message === "Expired JWT Token") {
              this.REFRESH_TOKEN_HANDLER().then(() => {
                this.getTarifCells();
              });
            } else {
              console.error("Ошибка в получении getTarifCells" + e.message);
            }
          }
        });
    },
    // Получаем таблицу с данными по тарифам заказа машины
    async getTarifCells() {
      if (this.getThisOrder.orderType === 1) {
        this.zaprosUrl = `/api/client/order/rate/get/${this.getThisOrder.orderId}`;
      } else {
        this.zaprosUrl = `/api/client/order/rate/loader/get/${this.getThisOrder.orderId}`;
      }
      await this.axios
        .get(this.zaprosUrl, {
          headers: { Authorization: `Bearer ${this.token}` }
        })
        .then((response) => {
          this.tarifCells = response.data.response[0];
        })
        .catch((e) => {
          if (e.response) {
            const { code, message } = e.response.data;
            if (code === 401 && message === "Expired JWT Token") {
              this.REFRESH_TOKEN_HANDLER().then(() => {
                this.getTarifCells();
              });
            } else {
              console.error("Ошибка в получении getTarifCells" + e.message);
            }
          }
        });
    },
    // Отменяем заказ по ID
    async cancelOrder() {
      this.loadingButton = true;
      await this.axios
        .post(
          `/api/client/order/cancel/${this.getThisOrder.orderId}`,
          {},
          {
            headers: { Authorization: `Bearer ${this.token}` }
          }
        )
        .then((response) => {
          this.UPDATE_ORDER(response.data.response).then(() => {
            this.loadingButton = false;
            this.closeModalOrderNoUpdate();
            this.$emit("getCancelOrder", this.getThisOrder.orderId);
          });
        })
        .catch((e) => {
          const { code, message } = e.response.data;
          if (code === 401 && message === "Expired JWT Token") {
            this.REFRESH_TOKEN_HANDLER().then(() => {
              this.cancelOrder();
            });
          } else {
            console.error("Ошибка в получении cancelOrder" + e.message);
          }
        });
    },
    // Копирование заказа
    copyOrder(order) {
      this.$emit("copyOrder", order);
    },
    // Возобновление заказа
    async reopenOrder(order) {
      this.loadingButton = true;
      await this.axios
        .post(
          `/api/client/order/return/${order}`,
          {},
          {
            headers: { Authorization: `Bearer ${this.token}` }
          }
        )
        .then((response) => {
          this.UPDATE_ORDER(response.data.response).then(() => {
            this.loadingButton = false;
            this.closeModalOrderNoUpdate();
            this.$emit("getReopenOrder", order);
          });
        })
        .catch((e) => {
          const { code, message } = e.response.data;
          if (code === 401 && message === "Expired JWT Token") {
            this.REFRESH_TOKEN_HANDLER().then(() => {
              this.reopenOrder();
            });
          } else {
            console.error("Ошибка в получении reopenOrder" + e.message);
          }
        });
    },
    // Проверка изменений в заказе
    async checkOrderChange() {
      await this.axios
        .get(`/api/client/order/get/${this.orderId}`, {
          headers: { Authorization: `Bearer ${this.token}` }
        })
        .then((response) => {
          this.thisOrder = response.data.response[0];
        })
        .catch((e) => {
          const { code, message } = e.response.data;
          if (code === 401 && message === "Expired JWT Token") {
            this.REFRESH_TOKEN_HANDLER().then(() => {
              this.checkOrderChange();
            });
          } else {
            console.error("Ошибка в получении checkOrderChange" + e.message);
          }
        });
    },
    // Проверка обновления заказа
    checkOrder() {
      this.checkOrderChange().then(() => {
        this.getTarifCells();
      });

      this.checkOrderInterval = setInterval(() => {
        this.checkOrderChange().then(() => this.getTarifCells());
      }, this.checkTimeInterval);
    },
    // Перевод даты для отображения в поле формы
    getTrueDataToInput(data, delimiter) {
      let date = new Date(data),
        getDay = date.getDate(),
        getYear = date.getFullYear(),
        getMonth = date.getMonth() + 1;
      if (+getDay < 10) {
        getDay = "0" + getDay;
      }
      if (+getMonth < 10) {
        getMonth = "0" + getMonth;
      }
      const newDate = getYear + delimiter + getMonth + delimiter + getDay;
      return newDate;
    },
    // Переходим на страницу профиля во вкладку тарифы, закрываем окно
    setActivePage() {
      // this.closeModalOrder();
      // this.CHANGE_ACTIVE_PAGE("profile");
      // this.CHANGE_PAGE_TAB({ index: 0, open: true });
      // this.CHANGE_PAGE_TAB({ index: 1, open: false });
      // this.CHANGE_PAGE_TAB({ index: 2, open: true });
      let routeData = this.$router.resolve({
        name: "profile",
        query: {
          data: "tarif",
          payment: this.userData.paymentType,
          tarif: +this.getThisOrder.orderSelectRate
        }
      });
      window.open(routeData.href, "_blank");
    },
    // Закрываем окно
    closeModalOrder() {
      this.ADD_ORDER_FROM_ROUTE(null);
      // Отменяем обновление заказа
      if (!this.viewGPS && !this.viewGPSLink) {
        clearInterval(this.checkOrderInterval);
        this.UPDATE_ORDER(this.thisOrder).then(() => {
          this.$emit("getUpdateOrder", this.thisOrder.orderId);
        });
      }
      this.$emit("closeModalOrderHandler", false);
      this.closeModal = true;
    },
    // Закрываем окно без обновления заказа
    closeModalOrderNoUpdate() {
      this.ADD_ORDER_FROM_ROUTE(null);
      // Отменяем обновление заказа
      if (!this.viewGPS && !this.viewGPSLink) {
        clearInterval(this.checkOrderInterval);
      }
      this.$emit("closeModalOrderHandler", false);
      this.$emit("getUpdateOrder", this.thisOrder.orderId);
      this.closeModal = true;
    },
    // Копирование данных в буфер
    copyExecutorData(id, copy) {
      this.copydata.push(id);
      const copytext = document.createElement("textarea");
      copytext.style = "left: -9999px; position: absolute";
      copytext.value = copy;
      document.body.appendChild(copytext);
      copytext.select();
      document.execCommand("copy");
      setTimeout(() => {
        this.copydata = [];
        copytext.remove();
      }, 2000);
    },
    // Копирование данных в буфер
    copyRouteArray(str, copy) {
      this.copyRouteArrayData.push(str);
      const copytext = document.createElement("textarea");
      copytext.style = "left: -9999px; position: absolute";
      const copyTextArray = copy.map((item) => item.address);
      copytext.value = copyTextArray.join("\n");
      document.body.appendChild(copytext);
      copytext.select();
      document.execCommand("copy");
      setTimeout(() => {
        this.copyRouteArrayData = [];
        copytext.remove();
      }, 2000);
    },
    // Звонок на телефон
    callToDriver(link) {
      window.open(`tel:${link}`);
    },
    // Открываем GPS в окне
    openGPSHandler(order) {
      this.openGPS = !this.openGPS;
      if (this.openGPS) {
        if (order.driverData.driverDevice) {
          if (order.orderStatus === 2 || order.orderStatus === 11) {
            console.log(order.driverData);
            this.openGPSTypeDriver = true;
            this.openGPSDriverId = order.driverData.driverId;
            this.openGPSDriverData = {
              data: order.driverData,
              tonn: order.tonn
            };
          } else if (order.orderStatus === 3) {
            this.openGPSTypeOrder = true;
            this.openGPSOrderId = order.orderId;
          }
        } else if (order.gps) {
          this.openGPSLink = order.gps;
        }
      }
      // this.openGPS ? (this.openGPSLink = link) : (this.openGPSLink = "");
      document.addEventListener("click", this.dropModalGPSHandler);
    },
    // Закрываем GPS по клику
    closeGPS() {
      this.openGPS = false;
      this.openGPSLink = null;
      document.removeEventListener("click", this.dropModalGPSHandler);
    },
    // Документы к рейсу
    openDocs(link) {
      window.open(link, "_blank");
    },
    // Whatsapp
    openWhatsapp() {
      const link =
        "https://wa.me/" +
        this.userData.managerPhone.match(/[\d]/g).join("") +
        "?text=Заказ " +
        this.getThisOrder.orderId;
      window.open(link, "_blank");
    },
    // Фото водителя по клику
    openLightbox() {
      this.toggler = !this.toggler;
    },
    openLightboxDocs(array) {
      array.forEach((item) => this.openDocs(item));
    },
    // Клик не на элементе модального окна
    dropModalHandler(e) {
      let el = this.$refs.openOrderModal;
      let target = e.target;
      if (el === target && el.contains(target)) {
        this.closeModalOrder();
      }
    },
    // Клик не по элементу второго окна
    dropModalGPSHandler(e) {
      let el = this.$refs.newGPSModal;
      let target = e.target;
      if (el === target && el.contains(target)) {
        this.closeGPS();
      }
    },
    // ПРОКРУТКА
    // Добавляем и убираем отступ снизу у подвала модального окна
    getMargin() {
      document.querySelector(".modal--footer").style = "margin-bottom: 6px";
    },
    deleteMargin() {
      document.querySelector(".modal--footer").style = "margin-bottom: 0px";
    },
    // Ширина элемента таблицы
    getWidthElement() {
      this.elemWidth = +this.$refs.modal.clientWidth;
      this.allWidth = +this.$refs.modal.scrollWidth;
      this.resultWidth = this.allWidth - this.elemWidth;
      if (this.allWidth !== this.elemWidth) {
        this.rightViewTab = true;
        this.leftViewTab = false;
        this.getMargin();
      } else {
        this.leftViewTab = false;
        this.rightViewTab = false;
        this.deleteMargin();
      }
    },
    // Получение всех размеров элемента
    getElement() {
      this.getWidthElement();
    },
    // Прокрутка по скролу добавление и удаление кнопок
    getElementScroll() {
      if (
        this.$refs.modal.scrollLeft === 0 &&
        this.elemWidth !== this.allWidth
      ) {
        this.clearIntervalHandler();
        this.leftViewTab = false;
        this.rightViewTab = true;
      }
      if (
        this.$refs.modal.scrollLeft === this.resultWidth &&
        this.elemWidth !== this.allWidth
      ) {
        this.clearIntervalHandler();
        this.leftViewTab = true;
        this.rightViewTab = false;
      }
    },
    // Прокрутка с помощью кнопок влево
    leftViewTabHandler() {
      this.clearIntervalHandler();
      this.leftScroll = setInterval(() => {
        this.addSlideTable(-20, 200);
      }, 100);
      this.rightViewTab = true;
      if (this.$refs.modal.scrollLeft === 0) {
        this.clearIntervalHandler();
        this.leftViewTab = false;
      }
    },
    // Прокрутка с помощью кнопок вправо
    rightViewTabHandler() {
      this.clearIntervalHandler();
      this.rightScroll = setInterval(() => {
        this.addSlideTable(20, 200);
      }, 100);
      this.leftViewTab = true;
      if (this.$refs.modal.scrollLeft === this.resultWidth) {
        this.clearIntervalHandler();
        this.rightViewTab = false;
      }
    },
    // Прокрутка с помощью кнопок влево и вправо
    addSlideTable(step, period) {
      const startTime = Date.now();
      const render = () => {
        const dt = Date.now() - startTime;
        if (dt < period) {
          // console.log((step * dt) / period);
          this.$refs.modal.scrollLeft =
            this.$refs.modal.scrollLeft + (step * dt) / period;
          requestAnimationFrame(render);
        }
      };
      requestAnimationFrame(render);
    },
    // Убираем интервал
    clearIntervalHandler() {
      clearInterval(this.rightScroll);
      clearInterval(this.leftScroll);
    },
    // Закрыть второе модальное окно
    // closeSecondModal() {
    //   this.openSecondModal = false;
    //   document.removeEventListener("click", this.dropModalSecondHandler);
    // },
    // Клик не по элементу второго окна
    // dropModalSecondHandler(e) {
    //   let el = this.$refs.newSecondModal;
    //   let target = e.target;
    //   if (el === target && el.contains(target)) {
    //     this.closeSecondModal();
    //   }
    // }
    // Получение фото загруженных водителем
    async getPhotoDriverDocs() {
      await this.axios
        .get(`/api/client/order/doc/mobile/list/get/${this.orderId}`, {
          headers: { Authorization: `Bearer ${this.token}` }
        })
        .then((response) => {
          this.photoDriverDocs = response.data.response;
        })
        .catch((e) => {
          const { code, message } = e.response.data;
          if (code === 401 && message === "Expired JWT Token") {
            this.REFRESH_TOKEN_HANDLER().then(() => {
              this.getPhotoDriverDocs();
            });
          } else {
            console.error("Ошибка в получении getPhotoDriverDocs" + e.message);
          }
        });
    }
  },
  created() {
    document.addEventListener("click", this.dropModalHandler);
    // document.addEventListener("click", this.dropModalSecondHandler);
    if (!this.viewGPS && !this.viewGPSLink) {
      window.addEventListener("resize", this.getElement);
      this.checkOrder();
    }
    this.getTime = new Date(Date.now()).getTime();
    // Получение текущей даты
    this.getTimeNowInterval = setInterval(() => {
      this.getTime = new Date(Date.now()).getTime();
    }, 1000);
  },
  mounted() {
    if (!this.viewGPS && !this.viewGPSLink) {
      this.getElement();
      this.getPhotoDriverDocs();
      this.$refs.modal.addEventListener("scroll", this.getElementScroll);
    }
    // this.getTarifCells();
  },
  beforeDestroy() {
    if (!this.viewGPS && !this.viewGPSLink) {
      window.removeEventListener("resize", this.getElement);
      this.$refs.modal.removeEventListener("scroll", this.getElementScroll);
      this.clearIntervalHandler();
      // Очистка интервала получения текущей даты
      clearInterval(this.getTimeNowInterval);
      clearInterval(this.checkOrderInterval);
    }
  },
  destroyed() {
    if (!this.viewGPS && !this.viewGPSLink) {
      document.removeEventListener("click", this.dropModalHandler);
    }
  }
};
</script>

<style lang="scss">
.copydataModal {
  @include flex;
  background: $color-grey-light;
  border-radius: 8px;
  color: $color-black;
  font-size: 14px;
  padding: 8px 10px;
  position: absolute;
  right: -180px;
  top: -10px;
  z-index: 10;
}

.executor__none {
  @include flex;
  position: relative;

  h2 {
    color: $color-black !important;
    font-size: 18px !important;
    font-weight: normal !important;
    margin: 0 !important;
    width: 400px;
  }

  // &--loader {
  //   position: absolute;
  //   top: -90px;
  //   right: -200px;
  //   z-index: 10;
  // }

  &--loader {
    position: absolute;
    top: -20px;
    right: -140px;
    z-index: 10;
  }

  &--mini {
    margin-left: 0 !important;
    left: 0;
  }
}
</style>
